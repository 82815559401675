@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    @apply bg-gray-900 text-white;
  }

  @keyframes fadeInUp {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
  }

  .animate-fadeInUp {
    animation: fadeInUp 1s ease-in-out;
  }

  .hr {
    @apply  my-8 bg-gray-700 border-gray-100 border-solid  w-full
  }


  .card {
    @apply max-w-sm bg-white rounded-lg shadow-lg overflow-hidden animate-fadeInUp m-4
}
.card .card-tag {
    @apply inline-block bg-gray-200 dark:bg-gray-700 rounded-full text-gray-800 font-normal mr-1 py-1 px-2
}

.card .card-img {
    @apply w-full h-48 object-contain
}
  